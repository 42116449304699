.section__background-one {
  min-height: 450px;
  background-image: url(../images/background-one.jpg);
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: cover;
}

.section__background-two {
  min-height: 450px;
  background-image: url(../images/background-two.jpg);
  background-repeat: no-repeat;
  background-position: 45% 50%;
  background-size: cover;
  margin: 40px 0;
}

@media screen and (min-width: 768px) {
  .section__background-one, .section__background-two {
    background-attachment: fixed;
  }
}