@media screen and (max-width: 350px) {
  .home {
    .homecard__container {
      .home__title {
        font-size: 1.6rem;
      }
    }
  }

  .section__pitch {
    .pitch__medium {
      font-size: 1rem;
    }
  
    .pitch__large {
      font-size: 1.4rem;
    }
  }

  .footer {
    text-align: center;
  }
}