.about {
  .about__container {
    .about__wrapper {
      max-width: 768px;
      margin: 20px 1.5rem;
      display: flex;
      flex-direction: column;

      .about__text {
        .part-one, .part-two {
          padding-bottom: 30px;
        }
      }

      .about__img {
        margin: 0 2rem;
        height: 400px;
        text-align: center;

        .about__image {
          height: auto;
          width: 230px;
          -webkit-box-shadow: 25px 25px 0px 0px rgba(251,139,36,1);
          -moz-box-shadow: 25px 25px 0px 0px rgba(251,139,36,1);
          box-shadow: 25px 25px 0px 0px rgba(251,139,36,1);
        }
      }
    }

    .about__subtext {
      background-color: $secondary-color;
      padding: 20px 5px;
      margin: 20px 0 0 0;
      text-align: center;

      .subtext__title {
        font-size: $h2-font-size;
        color: $primary-color;
        text-align: center;
        text-transform: uppercase;
        margin: 20px 0;
      }

      .subtext__text {
        margin: 0 15px 15px;
      }
    }
  }
}