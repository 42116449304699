// Font and typography
$body-font: 'Raleway', sans-serif;
$header-font: 'Serlio';

$big-font-size: 2rem;
$h1-font-size: 1.5rem;
$h2-font-size: 1.25rem;
$h3-font-size: 1.125rem;
$normal-font-size: 1rem;
$small-font-size: .75rem;

// Colors
$primary-color: #FB8B24;
$secondary-color: #bac2a0;
$tertiary-color: #FBA04B; // hovers
$text-color-primary: #525252;
$text-color-secondary: #001219;
$text-color-tertiary: #FFFFFF;
$background-color-primary: #F3F5F7;
$background-color-secondary: #D9D9D9;
$input-color: #E0E0E0;
$error-color: #dc2f55;
$success-color: #2F5233;