* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: $background-color-primary;
  font-family: $body-font;
  font-size: 16px;
  line-height: 1.7;
  color: $text-color-primary;
}

h1, h2, h3, h4 {
  font-family: $header-font;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.section {
  padding: 2rem 0 4rem;
  min-height: 100vh;
  justify-content: center;
}

.section__title {
  font-size: $h1-font-size;
  color: $primary-color;
  text-align: center;
  text-transform: uppercase;
}

.section__subtitle {
  text-align: center;
  padding: 10px 0;
}

// Lay-out
.container {
  max-width: 768px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

.btn__container {
  @include btn__container;

  .btn {
    @include btn;
  }
}

.btn__container-appointment {
  @include btn__container;
  justify-content: center;
  margin: 20px 10px;

  .btn-appointment {
    @include btn;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-transform: uppercase;
    width: 280px;
    border: none;
  }
}

// Scrollbar
// ::-webkit-scrollbar {
//   width: .60rem;
//   background-color: $color-one;
//   border-radius: .5rem;
// }

// ::-webkit-scrollbar-thumb {
//   background-color: $scroll-thumb-color;
//   border-radius: .5rem;
// }

// ::-webkit-scrollbar-thumb:hover {
//   background-color: $text-color-light;
// }
