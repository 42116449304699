.sales__wrapper {
  margin: 45px 0;

  .sales__text:nth-child(1), .sales__text:nth-child(2) {
    border-left: 5px solid $secondary-color;
    margin: 20px 0;
    padding: 0 0 0 20px;
  }

  .sales__text:nth-child(3) {
    text-align: center;
    background-color: $secondary-color;
    padding: 20px;
  }

  .sales__text:nth-child(4), .sales__text:nth-child(5) {
    border-right: 5px solid $secondary-color;
    text-align: right;
    margin: 20px 0;
    padding: 0 20px 0 0;
  }

  .sales__text-orange:nth-child(1), .sales__text-orange:nth-child(2) {
    border-left: 5px solid $primary-color;
    margin: 20px 0;
    padding: 0 0 0 20px;
  }

  .sales__text-orange:nth-child(3) {
    text-align: center;
    background-color: $primary-color;
    padding: 20px;
  }

  .sales__text-orange:nth-child(4), .sales__text-orange:nth-child(5) {
    border-right: 5px solid $primary-color;
    text-align: right;
    margin: 20px 0;
    padding: 0 20px 0 0;
  }
}

.section__pitch {
  background-color: $secondary-color;
  text-align: center;
  padding: 35px 0;
  margin-bottom: 25px;

  .pitch__xsmall {
    font-size: $small-font-size;
    font-weight: 500;
  }

  .pitch__small {
    font-size: $normal-font-size;
  }
  
  .pitch__medium {
    font-size: $h3-font-size;
    font-weight: 700;
    line-height: 3;
  }

  .pitch__large {
    font-size: 1.6rem;
    color: $primary-color;
    font-weight: 700;
    font-style: italic;
  }

  .pitch__xlarge {
    font-size: $h1-font-size;
    font-weight: 700;
  }
}

.list__wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 10px 0 30px;

  .list__icon {
    align-self: center;

    .sales__star {
      height: 40px;
      width: auto;
    }
  }

  .list__text {
    text-align: left;
  }
}