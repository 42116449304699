.introduction__container {
  text-align: center;

  .intro__start {
    margin: 20px 0;
    padding: 10px 0;

    .intro__question {
      margin-top: 20px;
      font-style: italic;
      font-size: $h2-font-size;
    }
  }
  .list__container {
    .list__wrapper {
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin: 10px 0;

      .list__icon {
        align-self: center;

        .intro__checkmark {
          height: 50px;
          width: auto;
        }
      }

      .list__text {
        text-align: left;
      }
    }
  }

  .intro__slot {
    background-color: $secondary-color;
    padding: 20px 5px;
    margin: 20px 0;

    .intro__question {
      margin-bottom: 20px;
      font-style: italic;
      font-size: $h2-font-size;
    }
  }
}