@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Serlio';
  src: local('SerlioLTStd'),
    url('../fonts/SerlioLTStd.otf') format('truetype');
}

@import 'variables';
@import 'mixins';

@import 'layout/template';
@import 'layout/header';
@import 'layout/main';
@import 'layout/footer';

@import './layout/main/about';
@import './layout/main/contact';
@import './layout/main/home';
@import './layout/main/sales';
@import './layout/main/introduction';

@import 'components/navbar';
@import 'components/scrolltop';
@import 'components/imageBackground';

@import './mediaqueries/small.scss';
@import './mediaqueries/medium.scss';
@import './mediaqueries/large.scss';