.input__container {
  height: 50px;
  position: relative;
  width: 100%;
  margin: 43px 0;

  .input {
    background-color: $input-color;
    border-radius: 3px;
    border: 0;
    box-sizing: border-box;
    color: $text-color-primary;
    font-size: 18px;
    height: 100%;
    outline: 0;
    padding: 4px 20px 0;
    width: 100%;
  }

  .input.textarea {
    height: 150px;
    padding-top: 15px;
    resize: vertical;
    font-family: $body-font;
  }

  .cut {
    background-color: $background-color-primary;
    border-radius: 10px;
    height: 20px;
    left: 20px;
    position: absolute;
    top: -20px;
    transform: translateY(0);
    transition: transform 200ms;
    width: 110px;
  }

  .input:focus ~ .cut,
  .input:not(:placeholder-shown) ~ .cut {
    transform: translateY(8px);
  }

  .placeholder {
    left: 20px;
    line-height: 14px;
    pointer-events: none;
    position: absolute;
    transform-origin: 0 50%;
    transition: transform 200ms, color 200ms;
    top: 20px;
  }

  .input:focus ~ .placeholder,
  .input:not(:placeholder-shown) ~ .placeholder {
    transform: translateY(-30px) translateX(10px) scale(0.75);
  }

  .input:not(:placeholder-shown) ~ .placeholder {
    color: $input-color;
  }

  .error {
    font-size: 12px;
    color: $error-color;
    text-align: left;
    padding-top: 5px;
    padding-left: 5px;
    font-weight: 700;
  }

  .success {
    font-size: 12px;
    color: $success-color;
    text-align: left;
    padding-top: 5px;
    padding-left: 5px;
    font-weight: 700;
  }

}

.btn__container-contact {
  @include btn__container;
  justify-content: flex-start;
  margin: 0;
  margin-top: 145px;
}

.btn {
  @include btn;
  width: 100px;
  height: 50px;
  line-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0 5px;
}

.spinner {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}