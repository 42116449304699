.footer {
  display: flex;
  height: auto;
  padding-top: 1rem;
  padding-bottom: 5rem;
  background-color: $secondary-color;

  .top-content__container {
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .footer__title {
      font-size: $h2-font-size;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .socials__container {
      .social__icon {
        height: 35px;
        width: auto;
        color: $text-color-primary;
      }

      .social__icon:hover {
        color: $primary-color;
      }
    }

    .form__newsletter {
      display: flex;
      margin: 15px 0 30px 0;

      .input__container.newsletter {
        margin: 0;
      }
  
      .btn__container-footer {
        @include btn__container;
        justify-content: flex-start;
        margin: 0;

        .btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 5px;
          padding: 0 5px;
        }
      }

      .cut-newsletter {
        background-color: $secondary-color;
      }

      .spinner {
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
      }

      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    }
  }
  
  .bottom-content__container {
    margin: 15px 0 0 0;
    text-align: center;
    font-size: $small-font-size;

    .footer__link {
      color: $text-color-primary;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}