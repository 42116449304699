@mixin btn__container {
  height: auto;
  display: flex;
  text-align: center;
  justify-content: flex-end;
  margin: 10px 10px 20px;
}

@mixin btn {
  background-color: $primary-color;
  position: relative;
  color: $text-color-tertiary;
  width: 175px;
  height: 64px;
  line-height: 64px;
  transition: all 0.3s;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  border: none;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background-color: $tertiary-color;
  }
}