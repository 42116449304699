.home {
  padding: 0 0 4rem;
  min-height: 100vh;
  background-image: url(../images/home-1.jpg);
  background-color: $background-color-primary;
  background-position: 25% 35%;
  background-size: cover;
  justify-content: center;

  .logo__container {
    text-align: center;
    margin: 10px;

    .logo {
      height: 200px;
      width: auto;
    }
  }

  .homecard__container {
    background-color: rgba(217, 217, 217, 0.45);
    height: auto;
    max-width: 440px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 5px;

    .home__title {
      text-align: right;
      font-family: "Serlio";
      font-size: 2rem;
      line-height: 1.2;
      margin: 20px 10px 10px;
      color: $text-color-secondary;
    }

    .home__subtitle {
      text-align: right;
      margin: 10px;
      color: $text-color-secondary;
    }
  }
}