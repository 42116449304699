@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
  font-family: "Serlio";
  src: local("SerlioLTStd"), url("../fonts/SerlioLTStd.otf") format("truetype");
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #F3F5F7;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  line-height: 1.7;
  color: #525252;
}

h1, h2, h3, h4 {
  font-family: "Serlio";
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.section {
  padding: 2rem 0 4rem;
  min-height: 100vh;
  justify-content: center;
}

.section__title {
  font-size: 1.5rem;
  color: #FB8B24;
  text-align: center;
  text-transform: uppercase;
}

.section__subtitle {
  text-align: center;
  padding: 10px 0;
}

.container {
  max-width: 768px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

.btn__container {
  height: auto;
  display: flex;
  text-align: center;
  justify-content: flex-end;
  margin: 10px 10px 20px;
}
.btn__container .btn {
  background-color: #FB8B24;
  position: relative;
  color: #FFFFFF;
  width: 175px;
  height: 64px;
  line-height: 64px;
  transition: all 0.3s;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  border: none;
  cursor: pointer;
  border-radius: 3px;
}
.btn__container .btn:hover {
  background-color: #FBA04B;
}

.btn__container-appointment {
  height: auto;
  display: flex;
  text-align: center;
  justify-content: flex-end;
  margin: 10px 10px 20px;
  justify-content: center;
  margin: 20px 10px;
}
.btn__container-appointment .btn-appointment {
  background-color: #FB8B24;
  position: relative;
  color: #FFFFFF;
  width: 175px;
  height: 64px;
  line-height: 64px;
  transition: all 0.3s;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  border: none;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-transform: uppercase;
  width: 280px;
  border: none;
}
.btn__container-appointment .btn-appointment:hover {
  background-color: #FBA04B;
}

.header {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: #F3F5F7;
}

.footer {
  display: flex;
  height: auto;
  padding-top: 1rem;
  padding-bottom: 5rem;
  background-color: #bac2a0;
}
.footer .top-content__container {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.footer .top-content__container .footer__title {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 10px;
}
.footer .top-content__container .socials__container .social__icon {
  height: 35px;
  width: auto;
  color: #525252;
}
.footer .top-content__container .socials__container .social__icon:hover {
  color: #FB8B24;
}
.footer .top-content__container .form__newsletter {
  display: flex;
  margin: 15px 0 30px 0;
}
.footer .top-content__container .form__newsletter .input__container.newsletter {
  margin: 0;
}
.footer .top-content__container .form__newsletter .btn__container-footer {
  height: auto;
  display: flex;
  text-align: center;
  justify-content: flex-end;
  margin: 10px 10px 20px;
  justify-content: flex-start;
  margin: 0;
}
.footer .top-content__container .form__newsletter .btn__container-footer .btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0 5px;
}
.footer .top-content__container .form__newsletter .cut-newsletter {
  background-color: #bac2a0;
}
.footer .top-content__container .form__newsletter .spinner {
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.footer .bottom-content__container {
  margin: 15px 0 0 0;
  text-align: center;
  font-size: 0.75rem;
}
.footer .bottom-content__container .footer__link {
  color: #525252;
}
.footer .bottom-content__container .footer__link:hover {
  text-decoration: underline;
}

.about .about__container .about__wrapper {
  max-width: 768px;
  margin: 20px 1.5rem;
  display: flex;
  flex-direction: column;
}
.about .about__container .about__wrapper .about__text .part-one, .about .about__container .about__wrapper .about__text .part-two {
  padding-bottom: 30px;
}
.about .about__container .about__wrapper .about__img {
  margin: 0 2rem;
  height: 400px;
  text-align: center;
}
.about .about__container .about__wrapper .about__img .about__image {
  height: auto;
  width: 230px;
  box-shadow: 25px 25px 0px 0px rgb(251, 139, 36);
}
.about .about__container .about__subtext {
  background-color: #bac2a0;
  padding: 20px 5px;
  margin: 20px 0 0 0;
  text-align: center;
}
.about .about__container .about__subtext .subtext__title {
  font-size: 1.25rem;
  color: #FB8B24;
  text-align: center;
  text-transform: uppercase;
  margin: 20px 0;
}
.about .about__container .about__subtext .subtext__text {
  margin: 0 15px 15px;
}

.input__container {
  height: 50px;
  position: relative;
  width: 100%;
  margin: 43px 0;
}
.input__container .input {
  background-color: #E0E0E0;
  border-radius: 3px;
  border: 0;
  box-sizing: border-box;
  color: #525252;
  font-size: 18px;
  height: 100%;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
}
.input__container .input.textarea {
  height: 150px;
  padding-top: 15px;
  resize: vertical;
  font-family: "Raleway", sans-serif;
}
.input__container .cut {
  background-color: #F3F5F7;
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 110px;
}
.input__container .input:not(:-moz-placeholder-shown) ~ .cut {
  transform: translateY(8px);
}
.input__container .input:focus ~ .cut,
.input__container .input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}
.input__container .placeholder {
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}
.input__container .input:not(:-moz-placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}
.input__container .input:focus ~ .placeholder,
.input__container .input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}
.input__container .input:not(:-moz-placeholder-shown) ~ .placeholder {
  color: #E0E0E0;
}
.input__container .input:not(:placeholder-shown) ~ .placeholder {
  color: #E0E0E0;
}
.input__container .error {
  font-size: 12px;
  color: #dc2f55;
  text-align: left;
  padding-top: 5px;
  padding-left: 5px;
  font-weight: 700;
}
.input__container .success {
  font-size: 12px;
  color: #2F5233;
  text-align: left;
  padding-top: 5px;
  padding-left: 5px;
  font-weight: 700;
}

.btn__container-contact {
  height: auto;
  display: flex;
  text-align: center;
  justify-content: flex-end;
  margin: 10px 10px 20px;
  justify-content: flex-start;
  margin: 0;
  margin-top: 145px;
}

.btn {
  background-color: #FB8B24;
  position: relative;
  color: #FFFFFF;
  width: 175px;
  height: 64px;
  line-height: 64px;
  transition: all 0.3s;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  border: none;
  cursor: pointer;
  border-radius: 3px;
  width: 100px;
  height: 50px;
  line-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0 5px;
}
.btn:hover {
  background-color: #FBA04B;
}

.spinner {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.home {
  padding: 0 0 4rem;
  min-height: 100vh;
  background-image: url(../images/home-1.jpg);
  background-color: #F3F5F7;
  background-position: 25% 35%;
  background-size: cover;
  justify-content: center;
}
.home .logo__container {
  text-align: center;
  margin: 10px;
}
.home .logo__container .logo {
  height: 200px;
  width: auto;
}
.home .homecard__container {
  background-color: rgba(217, 217, 217, 0.45);
  height: auto;
  max-width: 440px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 5px;
}
.home .homecard__container .home__title {
  text-align: right;
  font-family: "Serlio";
  font-size: 2rem;
  line-height: 1.2;
  margin: 20px 10px 10px;
  color: #001219;
}
.home .homecard__container .home__subtitle {
  text-align: right;
  margin: 10px;
  color: #001219;
}

.sales__wrapper {
  margin: 45px 0;
}
.sales__wrapper .sales__text:nth-child(1), .sales__wrapper .sales__text:nth-child(2) {
  border-left: 5px solid #bac2a0;
  margin: 20px 0;
  padding: 0 0 0 20px;
}
.sales__wrapper .sales__text:nth-child(3) {
  text-align: center;
  background-color: #bac2a0;
  padding: 20px;
}
.sales__wrapper .sales__text:nth-child(4), .sales__wrapper .sales__text:nth-child(5) {
  border-right: 5px solid #bac2a0;
  text-align: right;
  margin: 20px 0;
  padding: 0 20px 0 0;
}
.sales__wrapper .sales__text-orange:nth-child(1), .sales__wrapper .sales__text-orange:nth-child(2) {
  border-left: 5px solid #FB8B24;
  margin: 20px 0;
  padding: 0 0 0 20px;
}
.sales__wrapper .sales__text-orange:nth-child(3) {
  text-align: center;
  background-color: #FB8B24;
  padding: 20px;
}
.sales__wrapper .sales__text-orange:nth-child(4), .sales__wrapper .sales__text-orange:nth-child(5) {
  border-right: 5px solid #FB8B24;
  text-align: right;
  margin: 20px 0;
  padding: 0 20px 0 0;
}

.section__pitch {
  background-color: #bac2a0;
  text-align: center;
  padding: 35px 0;
  margin-bottom: 25px;
}
.section__pitch .pitch__xsmall {
  font-size: 0.75rem;
  font-weight: 500;
}
.section__pitch .pitch__small {
  font-size: 1rem;
}
.section__pitch .pitch__medium {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 3;
}
.section__pitch .pitch__large {
  font-size: 1.6rem;
  color: #FB8B24;
  font-weight: 700;
  font-style: italic;
}
.section__pitch .pitch__xlarge {
  font-size: 1.5rem;
  font-weight: 700;
}

.list__wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 10px 0 30px;
}
.list__wrapper .list__icon {
  align-self: center;
}
.list__wrapper .list__icon .sales__star {
  height: 40px;
  width: auto;
}
.list__wrapper .list__text {
  text-align: left;
}

.introduction__container {
  text-align: center;
}
.introduction__container .intro__start {
  margin: 20px 0;
  padding: 10px 0;
}
.introduction__container .intro__start .intro__question {
  margin-top: 20px;
  font-style: italic;
  font-size: 1.25rem;
}
.introduction__container .list__container .list__wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 10px 0;
}
.introduction__container .list__container .list__wrapper .list__icon {
  align-self: center;
}
.introduction__container .list__container .list__wrapper .list__icon .intro__checkmark {
  height: 50px;
  width: auto;
}
.introduction__container .list__container .list__wrapper .list__text {
  text-align: left;
}
.introduction__container .intro__slot {
  background-color: #bac2a0;
  padding: 20px 5px;
  margin: 20px 0;
}
.introduction__container .intro__slot .intro__question {
  margin-bottom: 20px;
  font-style: italic;
  font-size: 1.25rem;
}

.nav {
  max-width: 968px;
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav .nav__logo,
.nav .nav__toggle {
  font-family: "Serlio";
  font-size: 1.25rem;
  color: #001219;
}
.nav .nav__logo:hover {
  color: #FBA04B;
}
.nav .nav__toggle,
.nav .change-theme {
  margin-top: 5px;
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.nav .change-theme.hidden {
  display: none;
}
.nav .nav__toggle:hover {
  color: #FBA04B;
}
.nav .nav__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}
.nav .nav__list .nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Raleway", sans-serif;
  font-size: 0.75rem;
  color: #001219;
}
.nav .nav__list .nav__link:hover {
  color: #FBA04B;
}
.nav .nav__list .active-link {
  color: #001219;
}
.nav .nav__list .nav__icon {
  font-size: 1.2rem;
}
.nav .nav__list .nav__item-logo {
  display: none;
}
.nav .nav__close {
  position: absolute;
  right: 1.3rem;
  bottom: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: #001219;
  margin-bottom: 1rem;
}
.nav .nav__close:hover {
  color: #FBA04B;
}
.nav .show-menu {
  bottom: 0;
}

.scroll-header {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
  position: fixed;
  bottom: 0;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .nav__menu {
    position: fixed;
    z-index: 100;
    bottom: -100%;
    left: 0;
    width: 100%;
    background-color: #F3F5F7;
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0px 2px 7px -1px rgb(0, 0, 0);
    border-radius: 1.5rem 1.5rem 0 0;
    transition: 0.3s;
  }
}
.scrollup {
  position: fixed;
  right: 1rem;
  bottom: -20%;
  background-color: #FB8B24;
  opacity: 0.8;
  padding: 5px 5px 0 5px;
  border-radius: 0.4rem;
  z-index: 10;
  transition: 0.4s;
}
.scrollup .scrollup__icon {
  font-size: 1.5rem;
  color: #FFF;
}

.scrollup:hover {
  background-color: #FBA04B;
}

.show-scroll {
  bottom: 5rem;
}

.section__background-one {
  min-height: 450px;
  background-image: url(../images/background-one.jpg);
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: cover;
}

.section__background-two {
  min-height: 450px;
  background-image: url(../images/background-two.jpg);
  background-repeat: no-repeat;
  background-position: 45% 50%;
  background-size: cover;
  margin: 40px 0;
}

@media screen and (min-width: 768px) {
  .section__background-one, .section__background-two {
    background-attachment: fixed;
  }
}
@media screen and (max-width: 350px) {
  .home .homecard__container .home__title {
    font-size: 1.6rem;
  }
  .section__pitch .pitch__medium {
    font-size: 1rem;
  }
  .section__pitch .pitch__large {
    font-size: 1.4rem;
  }
  .footer {
    text-align: center;
  }
}
@media screen and (min-width: 568px) {
  .home .homecard__container {
    justify-self: flex-end;
  }
  .about .about__container .about__wrapper .about__img {
    text-align: center;
    height: 400px;
  }
  .about .about__container .about__wrapper .about__img .about__image {
    height: auto;
    width: 230px;
  }
  .footer .top-content__container {
    flex-direction: row;
    justify-content: space-between;
  }
  .footer .top-content__container .newsletter__container {
    text-align: right;
  }
}
@media screen and (min-width: 768px) {
  html body .section {
    margin: 0;
    scroll-margin-top: 10rem;
  }
  html body .header {
    top: 0;
    bottom: initial;
    display: flex;
    justify-content: center;
    background-color: transparent;
  }
  html body .header .nav {
    height: 10rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }
  html body .header .nav .nav__logo {
    display: none;
  }
  html body .header .nav .nav__list {
    display: flex;
    -moz-column-gap: 2rem;
         column-gap: 2rem;
    align-items: center;
  }
  html body .header .nav .nav__list .nav__item .nav__link {
    font-family: "Serlio";
    font-size: 1.25rem;
  }
  html body .header .nav .nav__icon,
  html body .header .nav .nav__close,
  html body .header .nav .nav__toggle {
    display: none;
  }
  html body .header .nav .nav__item-logo {
    display: block;
  }
  html body .header .nav .nav__item-logo .logo {
    height: 150px;
    width: auto;
  }
  html body .scroll-header {
    background-color: #F3F5F7;
  }
  html body .home .home__container {
    height: 100vh;
    min-height: 700px;
  }
  html body .home .logo__container {
    display: none;
  }
  html body .home .homecard__container {
    align-self: center;
    max-width: 550px;
    justify-self: flex-end;
  }
  html body .introduction {
    display: flex;
  }
  html body .about .about__container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  html body .about .about__container .about__wrapper {
    max-width: none;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-self: center;
    max-width: 950px;
  }
  html body .about .about__container .about__wrapper .about__text {
    max-width: 500px;
  }
  html body .about .about__container .about__wrapper .about__text:nth-child(3) {
    order: 4;
    text-align: right;
  }
  html body .about .about__container .about__wrapper .about__img {
    height: 500px;
  }
  html body .about .about__container .about__wrapper .about__img .about__image {
    align-self: center;
    height: auto;
    width: 300px;
  }
  html body .sales .section__wrapper {
    padding: 2rem 0 4rem;
    justify-content: center;
    display: flex;
  }
  html body .contact {
    display: flex;
    justify-content: center;
  }
  html body .footer {
    padding-top: 2rem;
    padding-bottom: 2rem;
    justify-content: center;
  }
}
@media screen and (min-width: 1024px) {
  .container {
    max-width: 1000px;
  }
  .home__container {
    max-width: 100vw;
  }
  .home__container .homecard__container {
    margin-right: 15%;
  }
  .home__container .homecard__container h1 {
    font-size: 4rem;
  }
  .introduction__container .list__container .list__wrapper {
    margin: 30px 0;
  }
  .introduction__container .intro__slot {
    padding: 30px 20px;
    margin-top: 50px;
  }
  .about .about__container .about__wrapper .about__img .about__image {
    height: 550px;
  }
  .about .about__subtext {
    display: flex;
    flex-direction: column;
  }
  .about .about__subtext .subtext__text {
    width: 1000px;
    align-self: center;
  }
}/*# sourceMappingURL=App.css.map */