@media screen and (min-width: 1024px) {
    .container {
      max-width: 1000px;
    }

    .home__container {
      max-width: 100vw;

      .homecard__container {
        margin-right: 15%;

        h1 {
          font-size: 4rem;
        }
      }
    }

    .introduction__container {
      .list__container {
        .list__wrapper {
          margin: 30px 0;
        }
      }

      .intro__slot {
        padding: 30px 20px;
        margin-top: 50px;
      }
    }

    .about {
      .about__container {
        .about__wrapper {
          .about__img {
            .about__image {
              height: 550px;
            }
          }
        }
      }

      .about__subtext {
        display: flex;
        flex-direction: column;

        .subtext__text {
          width: 1000px;
          align-self: center;
        }
      }
    }
}