@media screen and (min-width: 568px) {
  .home {
    .homecard__container {
      justify-self: flex-end;
    }
  }

  .about {
    .about__container {
      .about__wrapper {
        .about__img {
          text-align: center;
          height: 400px;

          .about__image {
            height: auto;
            width: 230px;
          }
        }
      }
    }
  }

  .footer {
    .top-content__container {
      flex-direction: row;
      justify-content: space-between;

      .newsletter__container {
        text-align: right;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  html {
    body {
      .section {
        margin: 0;
        scroll-margin-top: 10rem;
      }

      .header {
        top: 0;
        bottom: initial;
        display: flex;
        justify-content: center;
        background-color: transparent;

        .nav {
          height: 10rem;
          column-gap: 1rem;

          .nav__logo {
            display: none;
          }

          .nav__list {
            display: flex;
            column-gap: 2rem;
            align-items: center;

            .nav__item {
              .nav__link {
                font-family: $header-font;
                font-size: $h2-font-size;
              }
            }
          }

          .nav__icon,
          .nav__close,
          .nav__toggle {
            display: none;
          }

          .nav__item-logo {
            display: block;

            .logo {
              height: 150px;
              width: auto;
            }
          }
        }
      }

      .scroll-header {
        background-color: $background-color-primary;
      }

      .home {
        .home__container {
          height: 100vh;
          min-height: 700px;
        }

        .logo__container {
          display: none;
        }

        .homecard__container {
          align-self: center;
          max-width: 550px;
          justify-self: flex-end;
        }
      }

      .introduction {
        display: flex;
      }

      .about {
        .about__container {
          display: flex;
          justify-content: center;
          flex-direction: column;

          .about__wrapper {
            max-width: none;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            align-self: center;
            max-width: 950px;

            .about__text {
              max-width: 500px;

              &:nth-child(3) {
                order: 4;
                text-align: right;
              }
            }

            .about__img {
              height: 500px;

              .about__image {
                align-self: center;
                height: auto;
                width: 300px;
              }
            }
          }
        }
      }

      .sales {
        .section__wrapper {
          padding: 2rem 0 4rem;
          justify-content: center;
          display: flex;
        }
      }

      .contact {
        display: flex;
        justify-content: center;
      }

      .footer {
        padding-top: 2rem;
        padding-bottom: 2rem;
        justify-content: center;
      }
    }
  }
}